import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Section,
  M,
  GrayBorder,
  TripleGrid,
  H2BlackTitleMargin,
} from "../../Global"
import {
  BuyNowWrapper,
  H2BuyNowTitle,
  TripleGridContainer,
  DescContent,
  ContentHeight,
  ClickableArea,
  Arrow,
  MobileArrowElement,
} from "./styles"
import IVideoTemplate from "./types"
import TrainingCard from "../../Elements/TrainingCard"
import Button from "../../UI/Button"

const VideoTemplate = ({ videoTemplateData }: IVideoTemplate) => {
  console.log(videoTemplateData)
  const {
    videoName,
    authorName,
    trainingVideo,
    authorPicture,
    packageVideos,
    packageSlug,
    iFrame,
    price,
    intensity,
    rating,
    lenght,
    videoId,
  } = videoTemplateData
  console.log(packageVideos)
  const [detailActive, setDetailActive] = useState<boolean>(false)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const toggleDetails = () => {
    setDetailActive(!detailActive)
  }

  useEffect(() => {
    setHeight(ref.current.clientHeight ? ref.current.clientHeight : 0)
    function handleResize() {
      setHeight(ref.current.clientHeight ? ref.current.clientHeight : 0)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return (
    <>
      <Section>
        <Container>
          <M>
            <H2BlackTitleMargin>{videoName}</H2BlackTitleMargin>
            <TrainingCard
              authorName={authorName}
              trainingTitle={videoName}
              trainingVideo={trainingVideo}
              authorPicture={authorPicture}
              iFrame={iFrame}
              isInChallenge={false}
              isInPreview={true}
              price={price}
              intensity={intensity}
              rating={rating}
              lenght={lenght}
              videoId={videoId}
              packageSlug={packageSlug}
            />
            <BuyNowWrapper>
              <H2BuyNowTitle>JUŻ TERAZ WYKUP DOSTĘP</H2BuyNowTitle>
              <Button type="landing" slug="#">
                WYKUP DOSTĘP
              </Button>
            </BuyNowWrapper>

            <DescContent isActive={detailActive} height={height}>
              <ContentHeight ref={ref}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  ea commodo consequat. Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                  in culpa qui officia deserunt mollit anim id est laborum.
                </p>
              </ContentHeight>
            </DescContent>
            <MobileArrowElement>
              <ClickableArea onClick={toggleDetails}>
                <Arrow isActive={detailActive} />
              </ClickableArea>
            </MobileArrowElement>
          </M>
        </Container>
      </Section>
      <GrayBorder />
      <Section>
        <TripleGridContainer>
          <M>
            <p>POZOSTAŁE FILMY W TYM PAKIECIE</p>
            <TripleGrid>
              {packageVideos.map((c: any, idx: number) => (
                <TrainingCard
                  key={idx}
                  authorName={c.author.name}
                  trainingTitle={c.video.name}
                  authorPicture={c.author.picture_icon}
                  trainingVideo={c.video}
                  videoSlug={c.video.slug}
                  iFrame={c.video.iFrame}
                  price={c.video.price}
                  intensity={c.video.intensity}
                  rating={c.video.rating}
                  lenght={c.video.lenght}
                  videoId={c.video.id}
                  packageSlug={c.packageSlug}
                  isInChallenge={false}
                />
              ))}
            </TripleGrid>
          </M>
        </TripleGridContainer>
      </Section>
    </>
  )
}

export default VideoTemplate
