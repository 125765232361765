import React, { ReactElement } from "react"
import Layout from "../components/UI/Layout"
import VideoTemplate from "../components/Sections/VideoTemplate"
import Head from "../components/Global/Head"

const VideoTemplatePage = ({ pageContext }: any): ReactElement => {
  console.log(pageContext)
  const videoTemplateData = {
    videoName: pageContext.video.video.name,
    authorName: pageContext.video.author.name,
    trainingVideo: pageContext.video.video,
    authorPicture: pageContext.video.author.picture_icon,
    packageVideos: pageContext._packageVideos,
    packageSlug: pageContext.packagee.slug,
    iFrame: pageContext.video.video.iFrame,
    price: pageContext.video.video.price,
    intensity: pageContext.video.video.intensity,
    rating: pageContext.video.video.rating,
    lenght: pageContext.video.video.lenght,
    videoId: pageContext.video.video.id,
  }

  return (
    <Layout hideLanding={true}>
      <Head
        pageTitle="Platforma Treningowa Online" //todo
        description="" //todo
        keywords="trening online, platforma treningowa online, video trening" //todo
      />
      <VideoTemplate videoTemplateData={videoTemplateData} />
    </Layout>
  )
}

export default VideoTemplatePage
