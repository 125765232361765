import styled, { css } from "styled-components"
import { Container } from "../../Global/"
import { IDescContent, IArrow } from "./types"

export const BuyNowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #262626;
  width: 100%;
  height: 215px;
  margin: 30px 0 30px 0;
  @media (max-width: 520px) {
    height: 130px;
  }
`
export const H2BuyNowTitle = styled.h2`
  margin: 10px 0px 20px 0px;
  font-size: 48px;
  text-align: center;
  @media (max-width: 520px) {
    font-size: 20px;
    margin: 0px 0px 5px 0px;
  }
`

export const TripleGridContainer = styled(Container)`
  margin: 10px 0px 50px 0px;
  & > div > div {
    gap: 15px;
  }
  & > div > p {
    margin: 15px 0px 20px 0px;
  }
`

export const DescContent = styled.div<IDescContent>`
  @media (max-width: 520px) {
    padding-bottom: 10px;
    transition: 0.3s;
    overflow: hidden;
    opacity: 1;
    position: relative;
    height: ${props => (props.isActive ? props.height + "px" : "56px")};
  }
`

export const ContentHeight = styled.div`
  @media (max-width: 520px) {
    position: relative;
  }
`
export const ClickableArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  cursor: pointer;
  & :hover {
    & > div {
      border-color: ${props => props.theme.color.lightGreen};
    }
  }
`
export const Arrow = styled.div<IArrow>`
  transition: 0.3s;
  border-right: 2px black solid;
  border-bottom: 2px black solid;
  width: 12px;
  height: 12px;
  transform: ${props =>
    props.isActive ? css`rotateZ(945deg)` : css`rotateZ(45deg)`};
  margin-right: 10px;
`

export const MobileArrowElement = styled.div`
  display: none;
  width: 100%;
  @media (max-width: 520px) {
    display: inline-block;
  }
`
